import './App.css';
import Pricing from './components/Pricing';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Login from './components/Login';   
import Register from './components/Register';
import Success from './components/Success';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
