import axios from "axios";
import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";


const AuthContext = createContext({});

const baseURL = process.env.REACT_APP_BASE_URL || '';

export default AuthContext;
export const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() =>
      localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens") || '{}')
        : null
    );
    const [user, setUser] = useState(() =>
      localStorage.getItem("authTokens")
        ? jwt_decode(localStorage.getItem("authTokens") || '{}')
        : null
    );
    const [loading, setLoading] = useState(true);
  
    const navigate = useNavigate();
  
    const loginUser = async (username, password, lookup_key) => {
      const response = await fetch(`${baseURL}/api/token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username,
          password
        })
      });
      const data = await response.json();
  
      if (response.status === 200) {
        setAuthTokens(data);
        setUser(jwt_decode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
        if (lookup_key) {
          const axiosTemp = axios.create({
            baseURL: `${baseURL}/`,
            headers: { Authorization: `Bearer ${data.access}` }
          });
          await axiosTemp.post('management/create-checkout-session/', {
            lookup_key: lookup_key
          }).then((response) => {
            window.location.href = response.data.url;
          });
        } else {
          navigate("/");
        }
      } else {
        alert("Something went wrong!");
      }
    };
    
    const registerUser = async (username, password, confirmPassword) => {
      const response = await fetch(`${baseURL}/api/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username,
          password,
          confirm_password: confirmPassword,
          email: ""
        })
      });
      if (response.status === 201) {
        navigate("/login");
      } else {
        //Read the error message from the response
        const data = await response.json();
        console.log(data);

      }
    };
  
    const logoutUser = () => {
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("authTokens");
      navigate("/");
    };
  
    const contextData = {
      user,
      setUser,
      authTokens,
      setAuthTokens,
      registerUser,
      loginUser,
      logoutUser
    };
  
    useEffect(() => {
      if (authTokens) {
        setUser(jwt_decode(authTokens.access));
      }
      setLoading(false);
    }, [authTokens, loading]);
  
    return (
      <AuthContext.Provider value={contextData}>
        {loading ? null : children}
      </AuthContext.Provider>
    );
  };