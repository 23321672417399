import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { Backdrop, CircularProgress } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import useAxios from '../utils/useAxios';
import AuthContext from "../context/AuthContext";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https:/www.truedata.com.mx">
        TDSE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const tiers = [
  {
    title: 'Básico',
    price: '180',
    description: [
      'Ventas ilimitadas',
      '90 días de respaldo',	
      'Incluye promociones',
      'Analítica de ventas',
    ],
    buttonText: 'Inicia ahora',
    buttonVariant: 'outlined',
    lookup_key: 'basic'
  },
  {
    title: 'Avanzado',
    subheader: 'Más popular',
    price: '210',
    description: [
      'Funciones básicas',
      '365 días de respaldo',
      'Emite facturas',
      'Envía recibos por email',
    ],
    buttonText: 'Inicia ahora', 
    buttonVariant: 'outlined',
    lookup_key: 'advanced'
  },
  {
    title: 'Empresa',
    price: '400',
    description: [
      'Funciones avanzadas',
      'Respaldos ilimitados',
      'Soporte prioritario',
      'Historial de inventarios',
    ],
    buttonText: 'Inicia ahora',
    buttonVariant: 'outlined',
    lookup_key: 'enterprise'
  },
];

const footers = [
  {
    title: 'Empresa',
    description: ['Información', 'Contacto'],
  },
  {
    title: 'Features',
    description: [
      'Ventas',
      'Clientes',
      'Productos',
      'Inventario',
      'Descuentos',
    ],
  },
  {
    title: 'Recursos',
    description: ['Documentación', 'Videos', 'FAQ'],
  },
  {
    title: 'Legal',
    description: ['Política de privacidad', 'Términos de uso'],
  },
];


function PricingContent() {
  const navigate = useNavigate();
  const axios = useAxios();

  const { logoutUser, user } = useContext(AuthContext); 

  const [open, setOpen] = React.useState(false);

  const handleProductSelection = async (lookup_key) => {
    if (user) {
      await axios.post('management/create-checkout-session/', {
        lookup_key: lookup_key
      })
        .then((response) => {
          window.location.href = response.data.url;
        }
      );
    } else {
      console.log('No user logged in');
      navigate('/login?lookup_key=' +  lookup_key);
    }
  }

  const handleLogout = () => {
    setOpen(true);
    //wait 1 second before logging out
    setTimeout(() => {
      logoutUser();
      setOpen(false);
    }, 1000);
  }

  const handleCreatePortalSession = async () => {
    await axios.post('management/create-portal-session/')
        .then((response) => {
            window.location.href = response.data.url;
        }
    );
}

  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            RECEIPT
          </Typography>
          <nav>
            <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
                Funciones
            </Link>
            <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              FAQ
            </Link>
            <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5 }}
            >
              Soporte
            </Link>
            {user && (
              <Link
              variant="button"
              color="text.primary"
              sx={{ my: 1, mx: 1.5, textDecoration: 'none', cursor: 'pointer' }}
              onClick={handleCreatePortalSession}
              >
                <b>{user.username}</b>
              </Link>
            )}
          </nav>
          {user ? (
            <Button
              color="primary"
              variant="outlined"
              sx={{ my: 1, mx: 1.5 }}
              onClick={() => handleLogout()}
            >
              Logout
            </Button>
          )
          : (
          <Button variant="outlined" sx={{ my: 1, mx: 1.5 }} onClick={() => navigate('/register')}>
            Login
          </Button>
          )}
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Punto de Venta
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Lleva el control de tus ventas en tus distintas sucursales y goza de un respaldo en línea disponible siempre.
          Analiza tus ventas, clientes, y productos. Envía recibos, facturas y promociones a tus clientes.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={12}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /sucursal
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} onClick={() => handleProductSelection(tier.lookup_key)}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="text.secondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}